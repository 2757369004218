
function calculateMonthsOfWork(startDate) {
    // Получаем текущую дату
    const currentDate = new Date();

    // Рассчитываем разницу в месяцах между текущей датой и датой начала работы
    return (currentDate.getFullYear() - startDate.getFullYear()) * 12 + (currentDate.getMonth() - startDate.getMonth());
}


const PointListItem = (props) => {
    return (
        <div onClick={props.onClick} className={`select-none bg-white my-2.5 mx-4 p-2 rounded-lg hover:bg-gray-200 hover:outline-none hover:ring-4 hover:ring-blue-600 ${props.focused}`}>
            <div className="flex flex-col justify-between">
                <div>
                    <span className="bg-blue-100 text-blue-800 text-sm font-medium mr-2 px-2.5 py-0.5 rounded dark:bg-blue-900 dark:text-blue-300">
                        {props.status === "open" ? "Работает" : "Закрыта"}
                    </span>
                    <span className="bg-yellow-100 text-purple-800 text-sm font-medium mr-2 px-2.5 py-0.5 rounded dark:bg-green-900 dark:text-green-300">
                        {props.city} {/* Город */}
                    </span>
                    <span className="bg-yellow-100 text-purple-800 text-sm font-medium mr-2 px-2.5 py-0.5 rounded dark:bg-green-900 dark:text-green-300">
                        {props.code}
                    </span>
                    <div className="text-gray-900 font-bold text-3xl mb-1">
                        {props.title}
                    </div>
                    {/* Дополнитейльные свойства */}
                    <h2 className="text-gray-700 text-base">
                        Дата открытия: {props.openingDate}
                    </h2>
                    {
                        props.status === "closed" ?
                            <h2 className="text-gray-700 text-base">
                                Дата закрытия: {props.closingDate}
                            </h2>
                            :
                            null
                    }
                    <h2 className="text-gray-700 text-base">
                        Стаж работы: {calculateMonthsOfWork(new Date(props.openingDate))} мес.
                    </h2>

                </div>
            </div>
        </div>
    )
}

export default PointListItem;