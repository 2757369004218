import React from "react";
import {useController} from "react-hook-form";
import {InputMask} from "@react-input/mask";

const colors = {
    error: {
        label: "text-red-600",
        input: "bg-red-50 border border-red-500 text-red-900 placeholder-red-700 focus:ring-red-500 focus:border-red-500"
    }
}

const MaskedInput =({ control, name, error, placeholder, label, required = true, minLength,mask, replacement}) => {
    const {
        field,
    } = useController({
        name,
        control,
        rules: { required: {value: required, message: "Это поле обязательное"}, minLength: {value: minLength, message: "Номер телефона не полный"} },
    })

    return(
        <div>
            <label
                className={`block mt-1 mb-0.5 font-medium ${error && colors.error.label}`}>
                {label}
            </label>
            <InputMask
                className={`text-sm rounded-lg block w-full p-2 border border-gray-300 ${error && colors.error.input}`}
                mask={mask}
                replacement={replacement}
                onChange={field.onChange}
                onBlur={field.onBlur}
                value={field.value}
                name={field.name}
                inputRef={field.ref}
            />
            {error && <p className="mt-1 mb-2 text-sm text-red-600">{error.message}</p>}
        </div>
    )
}

export default MaskedInput;