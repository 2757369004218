import {gql} from "@apollo/client";

export const GET_POINTS = gql`
query MyQuery {
  getPOSs {
    id
    title
    city {
      id
      name
    }
    manager {
      firstName
      secondName
      patronymic
    }
    openingDate
    closingDate
    status
    address
    code
    roomType {
      tittle
      abbreviation
    }
  }
}
`