import {gql} from "@apollo/client";


export const GET_DEPARTMENTS = gql`
query Departments {
  getDepartments {
    id
    abbreviation
    title
  }
}
`;

export const GET_POSITIONS = gql`
query getPositions($isBasic: Boolean, $department: ID) {
  getPositions(filters: {isBasic: $isBasic, department: {id: {exact: $department}}}) {
    id
    title
    abbreviation
  }
}
`;

