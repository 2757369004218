 import {Sidebar} from "../components/root/Sidebar";
import {Outlet} from "react-router-dom";


const Root = () => {
    return (
        <div className="flex flex-row">
            <div className='basis-1/6'>
                <Sidebar/>
            </div>
            <div className='basis-5/6 bg-slate-800'>
                <Outlet/>
            </div>
        </div>
    )
}

export default Root;