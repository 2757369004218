import React, {useEffect, useState} from 'react';

import BoxedTextWithTittle from "../base/span/BoxedTextWithTittle";
import Modal from "../base/modal/Modal";
import EmployeeEditModal from "./EmployeeEditModal";
import EmployeeDismissalModal from "./EmployeeDismissalModal";
import EmployeePositionChangeModal from "./EmployeePositionChangeModal";
import {useQuery} from "@apollo/client";
import {GET_EMP_CHANGES} from "../../graphql/query/employee";


function EmployeeProfile({setOpen, info, setEmployeeInfoUndefined}) {
    const [OpenModalEdit, setOpenModalEdit] = React.useState(false);
    const [openDismissalModal, setOpenDismissalModal] = React.useState(false);
    const [openPositionChangeModal, setOpenPositionChangeModal] = React.useState(false);

    const [changes, setChanges] = useState([]);

    console.log(info.id)

    const getEmpChanges = useQuery(GET_EMP_CHANGES, {
        variables: {
            id: info.id
        }
    });
    useEffect(() => {
        if (!getEmpChanges.loading) {
            if (getEmpChanges.data) {
                const structuredChanges = getEmpChanges.data.getEmployeeChanges.map((change) => {
                    return({
                        id: change.id,
                        date: change.date,
                        change: change.change,
                    })
                })
                const structuredHistory = getEmpChanges.data.getEmployeeHistories.map((history) => {
                    return({
                        id: history.id,
                        date: history.date,
                        change: history.position.title,
                    })
                })
                const changesList = [...structuredChanges, ...structuredHistory]
                setChanges([...structuredChanges, ...structuredHistory])
            }
        }
    }, [getEmpChanges.data, getEmpChanges.loading]);

    console.log(changes)

    return (
        <div className={"relative overflow-y-auto"} style={{height: "90vh", width: "45vw"}}>

            <Modal
                open={OpenModalEdit}
                component={<EmployeeEditModal title={"Редактирование сотрудника"} setOpen={setOpenModalEdit}/>}
            />

            <div className={"absolute top-4 right-4"}>
                <button
                    onClick={() => setOpenModalEdit(true)}
                    type="button" className="px-3 py-2 text-xs font-medium text-center inline-flex items-center text-white bg-blue-700 rounded-lg hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300">
                    Редактировать
                </button>
                <button
                    onClick={() => {
                        setOpen(false);
                        setEmployeeInfoUndefined();
                    }}
                    type="button" className="ml-1 px-3 py-2 text-xs font-medium text-center inline-flex items-center text-white bg-red-700 rounded-lg hover:bg-red-800 focus:ring-4 focus:outline-none focus:ring-red-300">
                    Закрыть
                </button>
            </div>

            <div className={"m-4 grid grid-cols-4 gap-4"}>

                <div className={"col-span-3 row-start-1"}>

                    <h2 className={"text-2xl font-bold"}>
                        {info.firstName} {info.secondName} {info.patronymic}
                    </h2>


                    <div className={"divide-y"}>
                        <div className={"my-1"}>
                            <p className="text-md font-bold text-gray-600 inline">Локация: </p>
                            <p className={"text-md font-bold inline"}> {info.city.name} </p>
                        </div>
                        <div className={"my-1"}>
                            <p className="text-md font-bold text-gray-600 inline">Место работы: </p>
                            <p className={"text-md font-bold inline"}> - </p>
                        </div>
                        <div className={"my-1"}>
                            <p className="text-md font-bold text-gray-600 inline">Отдел: </p>
                            <p className={"text-md font-bold inline"}> {info.position.department.abbreviation} </p>
                        </div>
                        <div className={"my-1"}>
                            <p className="text-md font-bold text-gray-600 inline">Должность: </p>
                            <p className={"text-md font-bold inline"}> {info.position.abbreviation} </p>
                        </div>
                    </div>


                </div>
                <div className={"col-span-4 row-span-4 col-start-1 row-start-2"}>

                    <div className={"grid grid-cols-2 gap-4"}>
                        <BoxedTextWithTittle title={"Логин"} value={"test.test"} />
                        <BoxedTextWithTittle title={"Почта"} value={info.workEmail} />
                    </div>

                    <div className={"grid grid-cols-2 gap-4"}>
                        <BoxedTextWithTittle title={"Номер телефона"} value={"+7 (777) 777-77-77"} />
                        <BoxedTextWithTittle title={"Руководитель"} value={"-"} />
                    </div>

                    <div className={"grid grid-cols-3 gap-4"}>
                        <BoxedTextWithTittle title={"Дата прихода"} value={info.arrivalDate} />
                        <BoxedTextWithTittle title={"Дата увольнения"} value={"-"} />
                        <BoxedTextWithTittle title={"Статус"} value={info.status === "works" ? "Работает" : "Уволен"} />
                    </div>

                    <div className={"grid grid-cols-3 gap-4"}>
                        <BoxedTextWithTittle title={"Пол"} value={info.gender ==="male" ? "Мужской" : "Женский"} />
                        <BoxedTextWithTittle title={"Дата Рождения"} value={info.birthDate} />
                        <BoxedTextWithTittle title={"Табельный номер"} value={info.personnelNumber} />
                    </div>

                    <Modal
                        open={openDismissalModal}
                        component={<EmployeeDismissalModal title={"Планирование увольнения"} setOpen={setOpenDismissalModal}/>}
                    />

                    <Modal
                        open={openPositionChangeModal}
                        component={<EmployeePositionChangeModal title={"Планирование изменения позиции"} setOpen={setOpenPositionChangeModal}/>}
                    />

                    <div className={"relative mt-2"}>
                        <div className={"absolute top-1 right-0"}>
                            <button
                                onClick={() => setOpenPositionChangeModal(true)}
                                type="button" className="px-3 py-2 text-xs font-medium text-center inline-flex items-center text-white bg-blue-700 rounded-lg hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800">
                                Изменение должности
                            </button>
                            <button
                                disabled={info.dismissalDate}
                                onClick={() => setOpenDismissalModal(true)}
                                type="button" className="ml-1 px-3 py-2 text-xs font-medium text-center inline-flex items-center text-white bg-red-700 rounded-lg hover:bg-red-800 focus:ring-4 focus:outline-none focus:ring-red-300 disabled:bg-gray-300 ">
                                Запланировать увольнение
                            </button>
                        </div>

                        <h2 className={"text-2xl font-bold"}>
                            История сотрудника
                        </h2>

                        <ol className="border-l-2 border-gray-400 mx-10 mt-8 pb-2">
                            {changes.map((change) =>
                                <li className={"mb-10"}>
                                    <div className="flex-start flex items-center">
                                        <div className={`-ml-[9px] -mt-2 mr-3 flex h-4 w-4 items-center justify-center rounded-full bg-green-600`}/>
                                        <h4 className="-mt-2 text-xl font-semibold sm:whitespace-pre-wrap">
                                            {change.date} - {change.change === "employment" ? "Трудоустройство" : change.change ==="fired" ? "Увольнение" : change.change}
                                        </h4>
                                    </div>
                                </li>
                            )}
                        </ol>

                    </div>

                </div>
            </div>
        </div>

    )
}

export default EmployeeProfile;