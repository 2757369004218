import {createBrowserRouter, Navigate, Outlet} from "react-router-dom";
import Root from "../routes/Root";
import {EmployeesPage} from "../pages/EmployeesPage";
import ProfilePage from "../pages/ProfilePage";
import PointsPage from "../pages/PointsPage";
import LocationsPage from "../pages/LocationsPage";
import {LoginPage} from "../pages/LoginPage";


export const no_login_router = createBrowserRouter([
    {
        path: "/",
        element: <Outlet/>,
        children: [
            {
                path: "",
                element: <LoginPage/>,
            },
            {
                path: "*",
                element: <Navigate to="/" replace={true}/>,
            }
        ]
    }
]);


export const router = createBrowserRouter([
    {
        path: "/",
        element: <Root/>,
        children: [
            {
                path: "employees",
                element: <EmployeesPage/>,
            },
            {
                path: "profile",
                element: <ProfilePage/>,
            },
            {
                path: "points",
                element: <PointsPage/>,
            },
            {
                path: "cities",
                element: <LocationsPage/>,
            },
            {
                path: "*",
                element: <Navigate to="/" replace={true}/>,
            }
        ]
    },
]);