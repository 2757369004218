import { gql } from '@apollo/client';

export const ADD_EMPLOYEE = gql`
mutation createEmployee(
  $firstName: String!,
  $secondName: String!,
  $patronymic: String
  $gender: String!,
  $arrivalDate: Date!,
  $city: ID,
  $position: ID!,
  $email: String,
  $phoneNumber: String,
  $birthDate: Date,
  $personnelNumber: String
) {
  createEmployee(
    data: {
      firstName: $firstName,
      secondName: $secondName,
      patronymic: $patronymic,
      gender: $gender,
      arrivalDate: $arrivalDate,
      city: {set: $city},
      position: {set: $position},
      workEmail: $email,
      phoneNumber: $phoneNumber,
      birthDate: $birthDate,
      personnelNumber: $personnelNumber
    }
  ) {
    id
  }
}
`;

