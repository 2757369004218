import {createColumnHelper, flexRender, getCoreRowModel, useReactTable} from "@tanstack/react-table";

const columnHelper = createColumnHelper()

const StandardTable = ({data}) => {

    const columns = [
        columnHelper.accessor('country', {
            cell: info => info.getValue(),
            footer: info => info.column.id
        }),
        columnHelper.accessor('region', {
            cell: info => info.getValue(),
            footer: info => info.column.id
        }),
        columnHelper.accessor('province', {
            cell: info => info.getValue(),
            footer: info => info.column.id
        }),
        columnHelper.accessor('city', {
            cell: info => info.getValue(),
            footer: info => info.column.id
        }),
    ]


    const table = useReactTable({
        data,
        columns,
        getCoreRowModel: getCoreRowModel(),
    })


    if (data)
        return(
        <div className={"w-full relative"} style={{height: "20vh"}}>
            <table className={"w-full text-sm text-left text-gray-500 overflow-y-auto"} >
                <thead className={"sticky top-0 text-xs text-white font-bold uppercase bg-blue-800"}>
                {table.getHeaderGroups().map(headerGroup => (
                    <tr key={headerGroup.id}>
                        {headerGroup.headers.map(header => (
                            <th className={"px-6 py-3"}
                                key={header.id}>
                                {header.isPlaceholder
                                    ? null
                                    : flexRender(
                                        header.column.columnDef.header,
                                        header.getContext()
                                    )}
                            </th>
                        ))}
                    </tr>
                ))}
                </thead>
                <tbody>
                {table.getRowModel().rows.map(row => (
                    <tr className={"odd:bg-white even:bg-gray-50 border-b"}
                        key={row.id}>
                        {row.getVisibleCells().map(cell => (
                            <td className={"px-6 py-4"}
                                key={cell.id}>
                                {flexRender(cell.column.columnDef.cell, cell.getContext())}
                            </td>
                        ))}
                    </tr>
                ))}
                </tbody>
            </table>
        </div>
    )
    else
        return(
            <div>
                Данных нет
            </div>
        )
}

export default StandardTable;