import React from 'react';

const Modal = ({open, component}) => {
    if (!open) return null;
    return (
        <>
            <div className={"flex items-center fixed inset-0 z-50"}>
                <div className="mx-auto bg-white rounded shadow-lg">
                    {component}
                </div>
            </div>
            <div className="opacity-50 fixed inset-0 z-40 bg-black"/>
        </>

    )
}

export default Modal;