import React, {useContext} from 'react';

import ReactRemoveScroll from "react-remove-scroll/dist/es5/Combination";
import {AuthContext} from "../context/AuthContext";
import {useForm} from "react-hook-form";



export const LoginPage = () => {
    const { loginUser, loginError } = useContext(AuthContext);

    const { register, formState: { errors }, handleSubmit } = useForm()

    const onSubmit = async (data) => {
        try {
            await loginUser(data.username, data.password);
        }
        catch (error) {
            console.error(error);
        }
    }


    return (
        <div className="bg-slate-800 min-h-screen flex flex-col justify-center items-center">
            <form
                className="bg-white p-6 rounded-lg shadow-md m-8"
                onSubmit={handleSubmit(onSubmit)}
                autoComplete="nope"
            >
                <div className="flex justify-center mb-4 hover:animate-spin">
                    <svg width="86" height="40" viewBox="0 0 86 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <g clipPath="url(#clip0_1_2)">
                            <path fillRule="evenodd" clipRule="evenodd" d="M41.444 40H72.1853C74.4625 33.3966 76.7396 26.7173 79.0927 20.038L83.3433 7.66603H75.2974L66.1889 33.852H51.8429L61.331 6.22391H63.9117L64.8226 9.79127L57.2321 32.1822H65.278L73.7034 6.75522L71.1986 0H55.1827C52.9056 6.67932 50.6284 13.3586 48.3513 20.038C45.9982 26.6414 43.7211 33.3207 41.444 40ZM15.0291 20.797H19.4316L21.7846 13.5863L24.1377 6.37571H21.9365H19.7352L17.3822 13.6622L15.0291 20.797ZM83.9506 5.8444L86 0H77.9541L75.9047 5.8444H83.9506ZM0 40H20.6461L22.7714 33.7002H10.3989L11.5375 30.8918L12.6761 27.8558C13.4351 27.8558 14.1183 27.8558 14.8773 27.8558C15.6364 27.8558 16.3195 27.8558 17.0786 27.8558C16.699 28.8425 16.3195 29.9051 15.94 30.8918L15.4086 32.4099C16.0159 32.4099 16.6231 32.4099 17.1545 32.4099L23.3027 32.334L27.4016 20.1898C29.6787 13.5104 31.88 6.83112 34.1571 0.0759013H17.3063L11.0821 6.75522L6.60371 20.1139C4.40247 26.7173 2.20124 33.3966 0 40ZM22.316 40H39.9259L42.0512 33.7002H31.7282L38.1041 15.6357L43.5693 0H35.6752C33.3981 6.75522 31.1968 13.4345 28.9197 20.1139C26.7184 26.7932 24.5172 33.3966 22.316 40ZM42.5825 32.0304L46.6814 20.1139C48.1236 15.9393 49.5658 11.6888 51.0079 7.51423H42.8102L40.0018 15.7116L34.3848 32.0304H42.5825ZM51.5393 5.7685C52.2224 3.87097 52.8297 1.89753 53.5128 0H45.391C44.7079 1.89753 44.1006 3.79507 43.4175 5.7685H51.5393Z" fill="#1653FE"/>
                        </g>
                        <defs>
                            <clipPath id="clip0_1_2">
                                <rect width="86" height="40" fill="white"/>
                            </clipPath>
                        </defs>
                    </svg>
                </div>
                <div className="relative rounded-md shadow-sm">
                    <input
                        className="form-control block w-full my-2 px-4 py-2 text-xl font-normal text-gray-700 bg-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
                        placeholder={"Логин"}
                        {...register("username", {required: true})}
                        aria-invalid={errors.password ? "true" : "false"}
                    />
                </div>
                <div className="relative rounded-md shadow-sm">
                    <input
                        className="form-control block w-full my-2 px-4 py-2 text-xl font-normal text-gray-700 bg-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
                        placeholder={"Пароль"}
                        type="password"
                        {...register("password", { required: true })}
                        aria-invalid={errors.password ? "true" : "false"}
                    />
                </div>
                {(errors.username || errors.password) && (
                    <div className="text-center text-red-500 text-xs mb-4">Заполните поля авторизации</div>
                )}
                {(loginError) && (
                    <div className="text-center text-red-500 text-xs mb-4">{loginError}</div>
                )}
                <button type="submit" className="bg-blue-600 text-white py-2 px-4 rounded-lg hover:bg-blue-700 w-full">
                    Войти
                </button>
            </form>
            <ReactRemoveScroll children={null}/>
        </div>
    )
}