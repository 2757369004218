import React from "react";
import Input from "../base/form/Input";
import {useFormik} from "formik";
import SearchSelect from "../base/form/SearchSelect";

const validate = values => {
    const errors = {};
    if(!values.firstName) {
        errors.firstName = 'Поле не должно быть пустым';
    }

    if(!values.secondName) {
        errors.secondName = 'Поле не должно быть пустым';
    }

    return errors;
}

const genders = [
    { value: 'male', label: 'Мужской' },
    { value: 'female', label: 'Женский' },
]



const EmployeeEditModal = ({title, setOpen, }) => {

    const formik = useFormik({
        initialValues: {
            firstName: '',
            secondName: '',
            patronymic: '',
            city: '',
            work_place: '',
            email: '',
            phone_number: '',
            gender: '',
            birthday: '',
            personal_number: '',
        },
        validate,
        onSubmit: values => {
            alert(JSON.stringify(values, null, 2));
        },
    });

    const options = [
        { value: 'chocolate', label: 'Chocolate' },
        { value: 'strawberry', label: 'Strawberry' },
        { value: 'vanilla', label: 'Vanilla' }
    ]


    return (
        <div className={"m-4"}>
            {/* header title */}
            <div className="p-3 border-b border-solid border-slate-300 rounded-t">
                <h3 className="text-center text-2xl font-bold">
                    {title}
                </h3>
            </div>

            {/*body*/}
            <div className="relative flex flex-col w-full grow">
                <form autoComplete={"nope"} onSubmit={formik.handleSubmit} className={""}>
                    <div className={"grid grid-cols-3 gap-4"}>
                        <Input
                            type={"text"}
                            id={"firstName"}
                            name={"firstName"}
                            onChange={formik.handleChange}
                            value={formik.values.firstName}
                            message={formik.errors.firstName}
                            label={"Имя"}
                        />
                        <Input
                            type={"text"}
                            id={"secondName"}
                            name={"secondName"}
                            onChange={formik.handleChange}
                            value={formik.values.secondName}
                            message={formik.errors.secondName}
                            label={"Фамилия"}
                        />
                        <Input
                            type={"text"}
                            id={"patronymic"}
                            name={"patronymic"}
                            onChange={formik.handleChange}
                            value={formik.values.patronymic}
                            label={"Отчество"}
                        />
                    </div>

                    <div className={"grid grid-cols-3 gap-4"}>
                        <SearchSelect
                            id={"city"}
                            name={"city"}
                            // onChange={formik.handleChange}
                            placeholder={"Выберите город"}
                            options={options}
                            label={"Город"}
                        />
                        <SearchSelect
                            id={"work_place"}
                            name={"work_place"}
                            // onChange={formik.handleChange}
                            placeholder={"Выберите место работы"}
                            options={options}
                            label={"Место работы"}
                            className={"col-span-2"}
                        />
                    </div>

                    <div className={"grid grid-cols-2 gap-4"}>
                        <Input
                            type={"text"}
                            id={"email"}
                            name={"email"}
                            onChange={formik.handleChange}
                            value={formik.values.email}
                            label={"Рабочая почта"}
                        />
                        <Input
                            type={"text"}
                            id={"phone_number"}
                            name={"phone_number"}
                            onChange={formik.handleChange}
                            value={formik.values.phone_number}
                            label={"Номер телефона"}
                        />
                    </div>

                    <div className={"grid grid-cols-3 gap-4"}>
                        <SearchSelect
                            id={"gender"}
                            name={"gender"}
                            // onChange={formik.handleChange}
                            placeholder={"Выберите пол"}
                            options={genders}
                            label={"Пол"}
                        />
                        <Input
                            type={"date"}
                            id={"birthday"}
                            name={"birthday"}
                            onChange={formik.handleChange}
                            value={formik.values.birthday}
                            label={"Дата рождения"}
                        />
                        <Input
                            type={"text"}
                            id={"personal_number"}
                            name={"personal_number"}
                            onChange={formik.handleChange}
                            value={formik.values.personal_number}
                            label={"Табельный номер"}
                        />
                    </div>

                </form>
            </div>

            {/*footer*/}
            <div className={"pt-3 border-t border-solid border-slate-300 rounded-b flex justify-end"}>
                <button
                    className={"text-white bg-red-700 hover:bg-red-800 focus:ring-4 focus:ring-red-300 font-medium rounded-lg text-sm px-5 py-2.5 mr-2"}
                    onClick={() => setOpen(false)}>
                    Отмена
                </button>
                <button
                    className={"text-white bg-green-700 hover:bg-green-800 focus:ring-4 focus:ring-green-300 font-medium rounded-lg text-sm px-5 py-2.5"}
                    onClick={() => formik.submitForm()}
                >
                    Сохранить
                </button>
            </div>
        </div>
    )
};

export default EmployeeEditModal;