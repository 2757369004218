import PageTitle from "../components/base/title/PageTitle";
import ReactRemoveScroll from "react-remove-scroll/dist/es5/Combination";
import React, {useEffect, useState} from "react";
import Select from "react-select";
import {useQuery} from "@apollo/client";
import {GET_POINTS} from "../graphql/query/points";
import {get} from "react-hook-form";
import PointListItem from "../components/points/PointListItem";

const PointsPage = () => {
    const statuses = [
        { value: 'open', label: 'Открыта' },
        { value: 'closed', label: 'Закрыта' }
    ]

    const [cities, setCities] = useState([]);
    const [workStatus, setWorkStatus] = useState(undefined);

    const [points, setPoints] = useState([]);

    const getPoints = useQuery(GET_POINTS);
    useEffect(() => {
        if(!getPoints.loading) {
            console.log(getPoints.data.getPOSs);
            setPoints(getPoints.data.getPOSs);
        }
    }, [getPoints.loading, getPoints.data]);


    return (
        <div className={"min-h-screen"}>
            <PageTitle title={'Торговые точки IP'}/>
            <ReactRemoveScroll className="columns-1 gap-1">
                <div>
                    <div className="mx-4 mb-1 grid grid-cols-4 gap-1">
                        <div className="relative col-span-2">
                            <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                                    <path strokeLinecap="round" strokeLinejoin="round" d="M21 21l-5.197-5.197m0 0A7.5 7.5 0 105.196 5.196a7.5 7.5 0 0010.607 10.607z" />
                                </svg>
                            </div>
                            <input
                                onChange={(e) => {console.log(e.target.value)}}
                                placeholder="Поиск" type="text" className="select-none bg-gray-50 border border-gray-300 text-gray-900 rounded-lg focus:ring-blue-500 focus:border-blue-500 w-full pl-10 p-2.5"
                            />
                        </div>

                        <button
                            className=" select-none w-full text-white bg-blue-700 hover:bg-blue-800 font-medium rounded-lg px-5 py-2.5 focus:outline-none"
                            type="button"
                            // onClick={() => setNewEmployeeModal(true)}
                        >
                            Добавить
                        </button>

                        <button
                            className="select-none w-full text-white bg-blue-700 hover:bg-blue-800 font-medium rounded-lg px-5 py-2.5 focus:outline-none"
                            type="button"
                            // onClick={() => setEmployeeProfileModal(true)}
                        >
                            Обновить
                        </button>

                    </div>

                    <div className={"mx-4 my-2 grid grid-cols-4 gap-1"}>
                        <div className={"col-span-1"}>
                            <Select
                                placeholder={"Город"}
                                backspaceRemovesValue
                                hideSelectedOptions={false}
                                isClearable
                                // options={cities}
                                className={"w-full"}
                                // onChange={(e) => {setCityID(e ? e.value : undefined)}}
                            />
                        </div>
                        <div className={"col-span-1"}>
                            <Select
                                placeholder={"Отдел"}
                                backspaceRemovesValue
                                hideSelectedOptions={false}
                                isClearable
                                // options={departments}
                                className={"w-full"}
                                // onChange={(e) => {setDepartmentID(e ? e.value : undefined)}}
                            />
                        </div>
                        <div className={"col-span-1"}>
                            <Select
                                placeholder={"Позиция"}
                                backspaceRemovesValue
                                hideSelectedOptions={false}
                                isClearable
                                // options={positions}
                                className={"w-full"}
                                // onChange={(e) => {setPositionID(e ? e.value : undefined)}}
                            />
                        </div>
                        <div className={"col-span-1"}>
                            <Select
                                placeholder={"Статус"}
                                backspaceRemovesValue
                                hideSelectedOptions={false}
                                isClearable
                                // options={statuses}
                                className={"w-full"}
                                // onChange={(e) => {setWorkStatus(e ? e.value : undefined)}}
                            />
                        </div>
                    </div>
                    <div>
                        <div className="overflow-y-auto pb-60 basis-2/5" style={{height: "90vh"}}>
                            {points.map((point) =>
                                <PointListItem
                                    key={point.id}
                                    id={point.id}
                                    title={point.title}
                                    status={point.status}
                                    code={point.code}
                                    city={point.city.name}
                                    openingDate={point.openingDate}
                                    closingDate={point.closingDate}
                                />
                            )}
                        </div>
                    </div>
                </div>
            </ReactRemoveScroll>
        </div>
    )
}

export default PointsPage;