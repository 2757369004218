import {gql} from "@apollo/client";


export const GET_CITIES = gql`
query Cities {
  getCities {
    id
    name
  }
}
`;

export const GET_LOCATIONS = gql`
query getLocations {
  getCities {
    id
    name
    province {
      id
      name
      region {
        id
        name
        country {
          id
          name
        }
      }
    }
  }
}
`



