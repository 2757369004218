import React from 'react'
import Select from 'react-select'


const SearchSelect = ({id, name, onChange, placeholder, options, label, className, disabled}) => {
    return (
        <div className={className}>
            <label
                htmlFor={id}
                className={`block mb-2 font-medium`}>
                {label}
            </label>
            <Select
                id={id}
                name={name}
                onChange={onChange}
                placeholder={placeholder}
                options={options}
                isDisabled={disabled}
            />
        </div>

    )
};

export default SearchSelect;