import React from 'react';
import ReactDOM from 'react-dom/client';

import { ApolloClient, InMemoryCache, ApolloProvider } from '@apollo/client';

import './styles/index.css';
import App from "./App";
import {AuthProvider} from "./context/AuthContext";

// Подключение к серверу GraphQL
const client = new ApolloClient({
    uri: 'https://madikabakov.xyz/graphql_api/',
    cache: new InMemoryCache(),
});


const root = ReactDOM.createRoot(document.getElementById('root'));


root.render(
    <ApolloProvider client={client}>
        <AuthProvider>
            <App/>
        </AuthProvider>
    </ApolloProvider>
);

