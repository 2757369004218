import React, {useContext} from "react";
import {AuthContext} from "./context/AuthContext";
import {no_login_router, router} from "./routers/Router";
import {RouterProvider} from "react-router-dom";


function App() {
    const { user } = useContext(AuthContext);

    if (!user) return (
        <RouterProvider router={no_login_router}/>
    );
    else return (
        <RouterProvider router={router}/>
    )
}

export default App;
