const BoxedTextWithTittle = (props) => {
    return (
        <div className={"grow mb-2"}>
            <span className="block mb-2 text-sm font-medium text-gray-900">
                {props.title}
            </span>
            <span className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg block w-full p-2">
                {props.value}
            </span>
        </div>
    )
}

export default BoxedTextWithTittle;