import {Link} from "react-router-dom";

const SidebarItem = (props) => {
    // Компонент кнопки в боковом меню

    return (
        <Link to={props.path} className={`select-none flex items-center px-4 py-2 ${props.location === props.path ? "text-gray-700 bg-gray-100 rounded-lg" : "text-gray-500 rounded-lg hover:bg-gray-100 hover:text-gray-700"}`}>
            {props.icon}
            <span className="ml-3 text-sm font-medium"> {props.name} </span>
        </Link>
    )
}

export default SidebarItem;