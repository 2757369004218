import PageTitle from "../components/base/title/PageTitle";
import ReactRemoveScroll from "react-remove-scroll/dist/es5/Combination";
import EmployeeListItem from "../components/employees/EmployeeListItem";
import EmployeeProfile from "../components/employees/EmployeeProfile";
import {useQuery} from "@apollo/client";
import React, {useEffect, useState} from "react";
import {GET_EMPLOYEE_FILTERS, GET_EMPLOYEES} from "../graphql/query/employee";
import Select from "react-select";
import Modal from "../components/base/modal/Modal";
import NewEmployeeModal from "../components/employees/NewEmployeeModal";


export function EmployeesPage() {
    const statuses = [
        { value: 'works', label: 'Работает' },
        { value: 'fired', label: 'Уволен' }
    ]

    const [cities, setCities] = useState([]);
    const [departments, setDepartments] = useState([]);
    const [positions, setPositions] = useState([]);
    const [workStatus, setWorkStatus] = useState(undefined);
    const [cityID, setCityID] = useState(undefined);
    const [positionID, setPositionID] = useState(undefined);
    const [departmentID, setDepartmentID] = useState(undefined);

    const [employees, setEmployees] = useState([]);
    const [employeeInfo, setEmployeeInfo] = useState(undefined);

    const [newEmployeeModal, setNewEmployeeModal] = useState(false);
    const [employeeProfileModal, setEmployeeProfileModal] = useState(false);


    const getEmployeeFilters = useQuery(GET_EMPLOYEE_FILTERS)
    const getEmployees = useQuery(GET_EMPLOYEES, {
        variables: {
            city: cityID,
            position: positionID,
            department: departmentID,
            status: workStatus
        }
    })

    useEffect(() => {
        if(!getEmployeeFilters.loading) {
            // console.log(getEmployeeFilters.data);
            const citiesOptions = getEmployeeFilters.data.getCities.map((city) => {
                return {value: city.id, label: city.name}
            })
            const departmentsOptions = getEmployeeFilters.data.getDepartments.map((department) => {
                return {value: department.id, label: department.abbreviation}
            })
            const positionsOptions = getEmployeeFilters.data.getPositions.map((position) => {
                return {value: position.id, label: position.abbreviation}
            })
            setCities(citiesOptions);
            setDepartments(departmentsOptions);
            setPositions(positionsOptions);
        }
    }, [getEmployeeFilters.data, getEmployeeFilters.loading]);

    useEffect(() => {
        if(!getEmployees.loading) {
            setEmployees(getEmployees.data.getEmployees)
            // console.log(getEmployees.data)
        }
    }, [getEmployees.data, getEmployees.loading, getEmployees.refetch, cityID, departmentID, positionID])

    useEffect(() => {
        if (employeeInfo) {
            setEmployeeProfileModal(true);
        }
    }, [employeeInfo])

    const setEmployeeInfoUndefined = () => {
        setEmployeeInfo(undefined);
    }

    return (
        <div className="min-h-screen">
            <PageTitle title={'Сотрудники'}/>
            <ReactRemoveScroll className="columns-1 gap-1">
                <div>
                    <div className="mx-4 mb-1 grid grid-cols-4 gap-1">
                        <div className="relative col-span-2">
                            <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                                    <path strokeLinecap="round" strokeLinejoin="round" d="M21 21l-5.197-5.197m0 0A7.5 7.5 0 105.196 5.196a7.5 7.5 0 0010.607 10.607z" />
                                </svg>
                            </div>
                            <input
                                onChange={(e) => {console.log(e.target.value)}}
                                placeholder="Поиск" type="text" className="select-none bg-gray-50 border border-gray-300 text-gray-900 rounded-lg focus:ring-blue-500 focus:border-blue-500 w-full pl-10 p-2.5"
                            />
                        </div>

                        <button
                            className=" select-none w-full text-white bg-blue-700 hover:bg-blue-800 font-medium rounded-lg px-5 py-2.5 focus:outline-none"
                            type="button"
                            onClick={() => setNewEmployeeModal(true)}
                        >
                            Добавить
                        </button>

                        <button
                            className="select-none w-full text-white bg-blue-700 hover:bg-blue-800 font-medium rounded-lg px-5 py-2.5 focus:outline-none"
                            type="button"
                            onClick={() => getEmployees.refetch()}
                        >
                            Обновить
                        </button>

                    </div>
                    <div className={"mx-4 my-2 grid grid-cols-4 gap-1"}>
                        <div className={"col-span-1"}>
                            <Select
                                placeholder={"Город"}
                                backspaceRemovesValue
                                hideSelectedOptions={false}
                                isClearable
                                options={cities}
                                className={"w-full"}
                                onChange={(e) => {setCityID(e ? e.value : undefined)}}
                            />
                        </div>
                        <div className={"col-span-1"}>
                            <Select
                                placeholder={"Отдел"}
                                backspaceRemovesValue
                                hideSelectedOptions={false}
                                isClearable
                                options={departments}
                                className={"w-full"}
                                onChange={(e) => {setDepartmentID(e ? e.value : undefined)}}
                            />
                        </div>
                        <div className={"col-span-1"}>
                            <Select
                                placeholder={"Позиция"}
                                backspaceRemovesValue
                                hideSelectedOptions={false}
                                isClearable
                                options={positions}
                                className={"w-full"}
                                onChange={(e) => {setPositionID(e ? e.value : undefined)}}
                            />
                        </div>
                        <div className={"col-span-1"}>
                            <Select
                                placeholder={"Статус"}
                                backspaceRemovesValue
                                hideSelectedOptions={false}
                                isClearable
                                options={statuses}
                                className={"w-full"}
                                onChange={(e) => {setWorkStatus(e ? e.value : undefined)}}
                            />
                        </div>
                    </div>
                    <div>
                        <div className="overflow-y-auto pb-60 basis-2/5" style={{height: "90vh"}}>
                            {employees.map((employee) => {
                                return (
                                    <EmployeeListItem
                                        key={employee.id}
                                        id={employee.id}
                                        status={employee.status}
                                        city={employee.city ? employee.city.name : "Неизвестно"}
                                        department={employee.position.department.abbreviation}
                                        position={employee.position.abbreviation}
                                        name={employee.firstName}
                                        surname={employee.secondName}
                                        patronymic={employee.patronymic}
                                        arrivalDate={employee.arrivalDate}
                                        dismissalDate={employee.dismissalDate}
                                        onClick={() => {
                                            setEmployeeInfo(employee);
                                        }}
                                    />
                                )})
                            }
                        </div>
                    </div>
                </div>

                <Modal
                    open={newEmployeeModal}
                    component={<NewEmployeeModal
                        title={"Добавить нового сотрудника"}
                        setOpen={setNewEmployeeModal}/>}
                />

                <Modal
                    open={employeeProfileModal}
                    component={<EmployeeProfile
                        info={employeeInfo}
                        setEmployeeInfoUndefined={setEmployeeInfoUndefined}
                        title={"Информация о сотруднике"}
                        setOpen={setEmployeeProfileModal}/>}
                />

            </ReactRemoveScroll>



        </div>
    )
}