import {useController} from "react-hook-form";
import Select from "react-select";
import React from "react";

const colors = {
    error: {
        label: "text-red-600",
        input: "bg-red-50 border border-red-500 text-red-900 placeholder-red-700 focus:ring-red-500 focus:border-red-500"
    }
}

const FormSelect = ({ control, name, error, options, placeholder, label, required = true, onChange, disabled}) => {

    const {
        field,
        // fieldState: { invalid, isTouched, isDirty },
        // formState: { touchedFields, dirtyFields },
    } = useController({
        name,
        control,
        rules: { required: required },
    })

    return (
        <div>
            {label ?
                <label
                    className={`block mt-1 mb-0.5 font-medium ${error && colors.error.label}`}>
                    {label}
                </label>
                :null}

            <Select
                backspaceRemovesValue
                hideSelectedOptions={false}
                isClearable
                placeholder={placeholder}
                options={options}
                onChange={onChange ? onChange : field.onChange}
                onBlur={field.onBlur}
                value={field.value}
                name={field.name}
                inputRef={field.ref}
                styles={{
                    control: (baseStyle) => ({
                        ...baseStyle,
                        borderColor: error ? "red" : "lightgrey",
                    })
                }}
                isDisabled={disabled}
            />
            {error && <p className="mt-1 mb-2 text-sm text-red-600">Это поле обязательное</p>}
        </div>
    )
}

export default FormSelect;