import {forwardRef} from "react";

const colors = {
    error: {
        label: "text-red-600",
        input: "bg-red-50 border border-red-500 text-red-900 placeholder-red-700 focus:ring-red-500 focus:border-red-500"
    }
}

const Input = forwardRef(function ({label, htmlFor, id, name, placeholder, message, onChange, value, type, disabled}, ref) {

    return (
        <div className="mb-3">
            <label
                htmlFor={htmlFor}
                className={`block mt-1 mb-0.5 font-medium ${message && colors.error.label}`}>
                {label}
            </label>
            <input
                type={type}
                id={id}
                name={name}
                className={`text-sm rounded-lg block w-full p-2 border border-gray-300 ${message && colors.error.input}`}
                placeholder={placeholder}
                onChange={onChange}
                value={value}
                disabled={disabled}
                ref={ref}
            />
            {message && <p className="mt-2 text-sm text-red-600">{message}</p>}
        </div>
    )
});

export default Input;