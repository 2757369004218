import {gql} from "@apollo/client";


export const GET_EMPLOYEE_FILTERS = gql`
query EmployeeFilters {
  getCities {
    id
    name
  }
  getDepartments {
    id
    abbreviation
    title
  }
  getPositions {
    id
    abbreviation
    title
  }
}
`;


export const GET_EMPLOYEES = gql`
query Employees($status: String, $city: ID, $position: ID, $department: ID) {
  getEmployees(
    filters: {status: {exact: $status}, city: {id: {exact: $city}}, position: {id: {exact: $position}, department: {id: {exact: $department}}}}
  ) {
    id
    firstName
    secondName
    patronymic
    city {
      name
      id
    }
    position {
      id
      abbreviation
      department {
        id
        abbreviation
        title
      }
      title
    }
    arrivalDate
    dismissalDate
    status
    personnelNumber
    phoneNumber
    workEmail
    gender
    birthDate
  }
}
`;

export const GET_EMP_CHANGES = gql`
query getEmpChanges($id: UUID!) {
  getEmployeeChanges(filters: {employee: {id: {exact: $id}}}) {
    id
    date
    change
  }
  getEmployeeHistories(filters: {employee: {id: {exact: $id}}}) {
    id
    date
    position {
      title
    }
  }
}
`