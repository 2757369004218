import React from "react";
import {useForm} from "react-hook-form";
import Input from "../base/form/Input";
import FormSelect from "../base/form/FormSelect";


const EmployeePositionChangeModal = ({title, setOpen}) => {

    const { control, register, formState: { errors }, handleSubmit } = useForm()

    const onSubmit = async (data) => {
        console.log(data)
    }

    return (
        <div className={"m-4"}>
            {/* header title */}
            <div className="p-3 border-b border-solid border-slate-300 rounded-t">
                <h3 className="text-center text-2xl font-bold">
                    {title}
                </h3>
            </div>

            {/*body*/}
            <div className="relative flex flex-col w-full grow">
                <form
                    id={"addPositionChange"}
                    autoComplete={"nope"}
                    onSubmit={handleSubmit(onSubmit)}
                    className={"mb-2"}
                >
                    <Input
                        type={"date"}
                        label={"Дата"}
                        {...register("dismissalDate", {required: true})}
                        aria-invalid={errors.email ? "true" : "false"}
                        message={errors.dismissalDate ? "Поле обязательное" : undefined}
                    />

                    <div className={"grid grid-cols-2 gap-4"}>
                        <FormSelect
                            name="department"
                            control={control}
                            placeholder="Выбор отдела"
                            // options={positions}
                            error={errors.department}
                        />
                        <FormSelect
                            name="position"
                            control={control}
                            placeholder="Выбор должности"
                            // options={positions}
                            error={errors.position}
                            // disabled={!departmentID}
                        />
                    </div>

                    <h1 className={"block mb-1 font-medium"} >Создать вакансию ?</h1>
                    <div className={"grid grid-cols-2 gap-2 mb-2"}>
                        <div className="flex items-center ps-4 border border-gray-200">
                            <input
                                id="bordered-radio-1"
                                type="radio" value=""
                                name="bordered-radio"
                                className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:outline-none focus:ring-0"
                            />
                            <label htmlFor="bordered-radio-1" className="w-full py-4 ms-2 text-sm font-medium text-gray-900">Да</label>
                        </div>
                        <div className="flex items-center ps-4 border border-gray-200 rounded">
                            <input
                                checked
                                id="bordered-radio-2"
                                type="radio"
                                value=""
                                name="bordered-radio"
                                className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:outline-none focus:ring-0"/>
                            <label htmlFor="bordered-radio-2" className="w-full py-4 ms-2 text-sm font-medium text-gray-900">Нет</label>
                        </div>
                    </div>

                </form>
            </div>

            {/*footer*/}
            <div className={"pt-3 border-t border-solid border-slate-300 rounded-b flex justify-end"}>
                <button
                    className={"text-white bg-red-700 hover:bg-red-800 focus:ring-4 focus:ring-red-300 font-medium rounded-lg text-sm px-5 py-2.5 mr-2"}
                    onClick={() => setOpen(false)}>
                    Отмена
                </button>
                <button
                    className={"text-white bg-green-700 hover:bg-green-800 focus:ring-4 focus:ring-green-300 font-medium rounded-lg text-sm px-5 py-2.5"}
                    form={"addPositionChange"}
                    type={"submit"}
                >
                    Сохранить
                </button>
            </div>
        </div>
    )
};

export default EmployeePositionChangeModal;