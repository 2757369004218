import React, {useEffect, useState} from "react";
import Input from "../base/form/Input";
import {useMutation, useQuery} from "@apollo/client";
import {GET_CITIES} from "../../graphql/query/locations";
import {GET_DEPARTMENTS, GET_POSITIONS} from "../../graphql/query/hr_app";
import {useForm} from "react-hook-form";
import FormSelect from "../base/form/FormSelect";
import MaskedInput from "../base/form/MaskedInput";
import {ADD_EMPLOYEE} from "../../graphql/mutations/employee";

const genders = [
    { value: 'male', label: 'Мужской' },
    { value: 'female', label: 'Женский' },
]


const NewEmployeeModal = ({title, setOpen, }) => {
    const [cities, setCities] = useState([]);
    const [departments, setDepartments] = useState([]);
    const [departmentID, setDepartmentID] = useState(undefined);
    const [positions, setPositions] = useState([]);

    const getCities = useQuery(GET_CITIES);
    useEffect(() => {
        if (!getCities.loading) {
            // console.log(getCities.data)
            setCities(
                getCities.data.getCities.map((city) => {
                    return {value: city.id, label: city.name}
                })
            )
        }
    }, [getCities.data, getCities.loading]);

    const getDepartments = useQuery(GET_DEPARTMENTS);
    useEffect(() => {
        if (!getDepartments.loading) {
            setDepartments(
                getDepartments.data.getDepartments.map((department) => {
                    return {value: department.id, label: department.abbreviation}
                })
            )
        }
    }, [getDepartments.data, getDepartments.loading])


    const getPositions = useQuery(GET_POSITIONS, {
        variables: {
            isBasic: true,
            department: departmentID ? departmentID.value : undefined
        }
    })
    // Загрузка должностей
    useEffect(() => {
        if (!getPositions.loading) {
            setPositions(
                getPositions.data.getPositions.map((position) => {
                    return {value: position.id, label: position.abbreviation}
                })
            )
        }
    }, [getPositions.data, getPositions.loading, departmentID]);

    const { control, register, formState: { errors }, handleSubmit, setValue } = useForm()
    const [addEmployee] = useMutation(ADD_EMPLOYEE);

    const onSubmit = async (data) => {
        addEmployee({variables: {
                firstName: data.firstName,
                secondName: data.secondName,
                patronymic: data.patronymic,
                gender: data.gender.value,
                arrivalDate: data.arrivalDate,
                city: data.city.value,
                position: data.position.value,
                email: data.email,
                phoneNumber: data.phoneNumber,
                birthDate: data.birthDate,
                personnelNumber: data.personnelNumber
            }}).catch((error) => {console.error(error)}).then(() => {
                // console.log(data);
                setOpen(false);
            })
    }




    return (
        <div className={"m-4"}>
            {/* header title */}
            <div className="p-3 border-b border-solid border-slate-300 rounded-t">
                <h3 className="text-center text-2xl font-bold">
                    {title}
                </h3>
            </div>

            {/*body*/}
            <div className="relative flex flex-col w-full grow">
                <form
                    id={"addEmployeeForm"}
                    autoComplete={"nope"}
                    onSubmit={handleSubmit(onSubmit)}
                >
                    <div className={"grid grid-cols-3 gap-4"}>
                        <Input
                            type={"text"}
                            label={"Фамилия"}
                            {...register("secondName", {required: true})}
                            aria-invalid={errors.password ? "true" : "false"}
                            message={errors.secondName ? "Поле обязательное" : undefined}
                        />
                        <Input
                            type={"text"}
                            label={"Имя"}
                            {...register("firstName", {required: true})}
                            aria-invalid={errors.password ? "true" : "false"}
                            message={errors.firstName ? "Поле обязательное" : undefined}
                        />
                        <Input
                            type={"text"}
                            label={"Отчество"}
                            {...register("patronymic")}
                        />
                    </div>

                    <div className={"grid grid-cols-3 gap-4"}>
                        <FormSelect
                            name="city"
                            control={control}
                            placeholder="Город"
                            options={cities}
                            error={errors.city}
                        />

                        <FormSelect
                            name="department"
                            control={control}
                            placeholder="Отдел"
                            options={departments}
                            error={errors.department}
                            onChange={(e) => {
                                setValue("department", e, {
                                    shouldValidate: true,
                                    shouldDirty: true
                                })
                                if (e) {
                                    setDepartmentID(e);
                                } else {
                                    setDepartmentID(undefined);
                                }
                            }}
                            value={departmentID}
                        />

                        <FormSelect
                            name="position"
                            control={control}
                            placeholder="Выбор должности"
                            options={positions}
                            error={errors.position}
                            disabled={!departmentID}
                        />

                    </div>

                    <div className={"grid grid-cols-2 mt-2 gap-4"}>
                        <Input
                            type={"text"}
                            label={"Рабочая почта"}
                            placeholder={"example@mail.com"}
                            {...register("email", {required: false})}
                            aria-invalid={errors.email ? "true" : "false"}
                            message={errors.email ? "Поле обязательное" : undefined}
                        />
                        <MaskedInput
                            name={"phoneNumber"}
                            control={control}
                            error={errors.phoneNumber}
                            minLength={18}
                            label={"Номер телефона"}
                            mask="+_ (___) ___-__-__"
                            replacement={{ _: /\d/ }}
                        />
                    </div>

                    <div className={"grid grid-cols-3 gap-4"}>
                        <FormSelect
                            name="gender"
                            control={control}
                            placeholder="Выберите пол"
                            options={genders}
                            error={errors.gender}
                            label={"Пол"}
                        />
                        <Input
                            type={"date"}
                            label={"Дата рождения"}
                            {...register("birthDate", {required: true})}
                            aria-invalid={errors.email ? "true" : "false"}
                            message={errors.birthdate ? "Поле обязательное" : undefined}
                        />
                        <Input
                            type={"text"}
                            label={"Табельный номер"}
                            placeholder={"ЛК00-******"}
                            {...register("personnelNumber", {required: false})}
                            aria-invalid={errors.email ? "true" : "false"}
                            message={errors.personnelNumber ? "Поле обязательное" : undefined}
                        />
                    </div>

                    <div>
                        <Input
                            type={"date"}
                            label={"Дата начала работы"}
                            {...register("arrivalDate", {required: true})}
                            aria-invalid={errors.arrivalDate ? "true" : "false"}
                            message={errors.arrivalDate ? "Поле обязательное" : undefined}
                        />
                    </div>

                </form>
            </div>

            {/*footer*/}
            <div className={"pt-3 border-t border-solid border-slate-300 rounded-b flex justify-end"}>
                <button
                    className={"text-white bg-red-700 hover:bg-red-800 focus:ring-4 focus:ring-red-300 font-medium rounded-lg text-sm px-5 py-2.5 mr-2"}
                    onClick={() => setOpen(false)}>
                    Отмена
                </button>
                <button
                    className={"disabled:bg-gray-300 text-white bg-green-700 hover:bg-green-800 focus:ring-4 focus:ring-green-300 font-medium rounded-lg text-sm px-5 py-2.5"}
                    form={"addEmployeeForm"}
                    type={"submit"}
                    // disabled={!isValid}
                >
                    Добавить
                </button>
            </div>
        </div>
    )
};

export default NewEmployeeModal;