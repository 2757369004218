import PageTitle from "../components/base/title/PageTitle";
import StandardTable from "../components/base/table/StandartTable";
import {useQuery} from "@apollo/client";
import {GET_LOCATIONS} from "../graphql/query/locations";
import {useEffect, useState} from "react";
import ReactRemoveScroll from "react-remove-scroll/dist/es5/Combination";

const LocationsPage = () => {
    const [cities, setCities] = useState([])

    const getLocations = useQuery(GET_LOCATIONS);

    useEffect(() => {
        if (!getLocations.loading) {
            console.log(getLocations.data.getCities);
            setCities(getLocations.data.getCities.map((city) => {
                return(
                    {
                        city: city.name,
                        province: city.province.name,
                        region: city.province.region.name,
                        country: city.province.region.country.name
                    }
                )
            }));
        }
    }, [getLocations.loading, getLocations.data]);

    console.log(cities)

    return (
        <div className={"min-h-screen"}>
            <PageTitle title={'Города'}/>
            {/*<ReactRemoveScroll>*/}
                <div className={"mx-4"}>
                    <StandardTable
                        data = {cities}
                    />
                </div>
            {/*</ReactRemoveScroll>*/}
        </div>
    )
}

export default LocationsPage;