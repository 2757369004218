import { createContext, useState, useEffect } from "react";
import { jwtDecode } from "jwt-decode";
import { redirect } from "react-router-dom";
import {API_URL} from "../settings";


export const AuthContext = createContext(null);

export const AuthProvider = ({ children }) => {
    const [authTokens, setAuthTokens] = useState(() =>
        localStorage.getItem("authTokens")
            ? JSON.parse(localStorage.getItem("authTokens"))
            : null
    );
    const [user, setUser] = useState(() =>
        localStorage.getItem("authTokens")
            ? jwtDecode(localStorage.getItem("authTokens"))
            : null
    );

    const [loadingState, setLoadingState] = useState(true);

    // Авторизация пользователя
    const [loginError, setLoginError] = useState('');
    const loginUser = async (username, password) => {
        const response = await fetch(API_URL + "api/login/token/", {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify({
                username,
                password
            })
        });
        const data = await response.json();

        if (response.status === 200) {
            setAuthTokens(data);
            setUser(jwtDecode(data.access));
            localStorage.setItem("authTokens", JSON.stringify(data));
            return redirect("/");
        }
        else {
            setLoginError("Логин или пароль неверный");
        }
    };

    // Выход из приложения
    const logoutUser = () => {
        setAuthTokens(null);
        setUser(null);
        localStorage.removeItem("authTokens");
        return redirect("/login");
    };

    // Регистрация функции
    const contextData = {
        loginError,
        user, setUser,
        loginUser, logoutUser,
        authTokens, setAuthTokens,
        // firstName, secondName, department, position, userID
    };

    useEffect(() => {
        // if (authTokens) {
        //     // setUser(authTokens.access);
        //     // setUserID(jwt_decode(authTokens.access).user_id);
        //     // setDepartment(jwt_decode(authTokens.access).department);
        //     // setPosition(jwt_decode(authTokens.access).position);
        //     // setFirstName(jwt_decode(authTokens.access).first_name);
        //     // setSecondName(jwt_decode(authTokens.access).second_name);
        // }
        setLoadingState(false);
    }, [authTokens, loadingState]);

    return (
        <AuthContext.Provider value={contextData}>
            {loadingState ? null : children}
        </AuthContext.Provider>
    );
}