import PageTitle from "../components/base/title/PageTitle";
import ReactRemoveScroll from "react-remove-scroll/dist/es5/Combination";

const ProfilePage = () => {
    return (
        <div className="min-h-screen">
            <PageTitle title={'Профиль'}/>
            <ReactRemoveScroll>{}</ReactRemoveScroll>
        </div>
    )
}

export default ProfilePage;